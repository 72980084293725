<template>
  <section>
    <img :src="require('~/assets/img/logo.svg')" alt="Ecologboek" aria-hidden="true" class="mx-auto">
    <form ref="login" method="post" class="w-3/4 sm:w-1/2 mx-auto" @submit.prevent="login">
      <h1 class="hidden">
        Inloggen
      </h1>
      <div class="relative mb-8 mt-12">
        <label for="email" class="absolute -top-3 left-2 bg-primary block mb-1 text-white font-bold px-1">E-mailadres</label>
        <input
          id="email"
          type="email"
          name="email"
          class="w-full border border-white text-white bg-transparent px-3 pt-4 pb-2"
          required
        >
      </div>
      <div class="relative mb-10">
        <label for="password" class="absolute -top-3 left-2 bg-primary block mb-1 text-white font-bold px-1">Wachtwoord</label>
        <input
          id="password"
          type="password"
          name="password"
          class="w-full border border-white text-white bg-transparent px-3 pt-4 pb-2"
          required
        >
      </div>
      <button
        type="submit"
        class="btn btn-secondary w-full"
      >
        Login
      </button>
      <button
        type="button"
        class="btn btn-secondary w-full mt-12"
        @click="loginWithGoogle"
      >
        <img src="~/assets/img/google.svg" alt="" aria-hidden="true" class="inline-block w-6 -my-1 mr-2">
        Sign in with google
      </button>
    </form>
    <nuxt-link :to="{name: 'inloggen-wachtwoord-vergeten'}" class="absolute bottom-6 left-0 right-0 text-center text-white hover:text-primary-lighter">
      Wachtwoord vergeten?
    </nuxt-link>
    <nuxt-link :to="{name: 'inloggen-over'}" class="absolute right-14 bottom-12 text-white hover:text-primary transition duration-200">
      <font-awesome-icon :icon="['far', 'info-circle']" size="lg"/>
    </nuxt-link>
  </section>
</template>

<script>
export default {
  layout: 'inloggen',
  auth: false,
  middleware: 'guest',
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.no_account !== undefined) {
      this.$toast.error('Er is geen account bekend met het gebruikte e-mailadres.');
      this.$router.push({query: {}});
    }
  },
  methods: {
    async login() {
      this.loading = true;

      try {
        const formData = new FormData(this.$refs.login);
        await this.$auth.loginWith('local', {data: formData});
        this.$toast.success('U bent succesvol ingelogd.');
      } catch (error) {
        this.$toast.error(this.errors.email);
      } finally {
        this.loading = false;
        this.$router.push('/');
      }
    },
    loginWithGoogle() {
      window.location.href = process.env.API_URL + '/login/redirect';
    },
  },
};
</script>
